import styles from "./videofragment.module.scss";
import Results from "../../Results";
import useVideoFragment from "./useVideoFragment";
import { useAppContext } from "../../utils/context/AppProvider";
import StepAndNotification from "../StepAndNotification";
import ControlButtons from "../ControlButtons";
import RequestLoader from "../RequestLoader";

const VideoFragment = () => {
  const {
    videoLoader,
    currentCam,
    videoRef,
    canvasRef,
    ROIcanvasRef,
    makeSnapshot,
    switchCam,
    handleUpload,
    reloadPage,
    imageUploaded,
  } = useVideoFragment();

  const {
    loadingMessage,
    matchedResult,
    requestLoader,
    matched,
    docImageURL,
    personImage,
    personBbox,
    docBbox,
    docImageParams,
    personImageURL,
  } = useAppContext();

  const roiCanvasStyle = `${styles["canvas"]} ${styles["canvas__ROI"]} ${
    requestLoader ? styles["canvas__invisible"] : ""
  }`;

  const photoCanvasStyles = `${styles["canvas"]} ${
    !requestLoader || imageUploaded ? styles["canvas__invisible"] : ""
  }`;

  const videoStyles = `${styles["video"]} ${
    currentCam === "user" ? styles["video__mirrored"] : ""
  } ${imageUploaded ? styles["video__hidden"] : ""}`;

  return (
    <>
      <StepAndNotification show={!matchedResult} />
      {!matched ? (
        <div className={styles["process__container"]}>
          <div className={styles["video__container"]}>
            <RequestLoader
              colorBlue={videoLoader}
              text={loadingMessage}
              show={videoLoader || requestLoader}
            />
            <video className={videoStyles} ref={videoRef} playsInline></video>
            {/* photo canvas */}
            <canvas ref={canvasRef} className={photoCanvasStyles}></canvas>
            {/* ROI canvas */}
            {!personImage && (
              <canvas ref={ROIcanvasRef} className={roiCanvasStyle}></canvas>
            )}
            {requestLoader && <div className={styles["tint"]}></div>}
          </div>
          <ControlButtons
            handleUpload={handleUpload}
            switchCam={switchCam}
            makeSnapshot={makeSnapshot}
            loader={requestLoader}
            showUploadButton={personImage !== null}
            show={!docImageURL}
          />
        </div>
      ) : (
        <Results
          image1Bbox={personBbox}
          image2Bbox={docBbox}
          image1URL={personImageURL!}
          image2URL={docImageURL!}
          image1Params={{
            width: videoRef.current!.videoWidth,
            height: videoRef.current!.videoHeight,
          }}
          image2Params={{ ...docImageParams! }}
          matchedResult={matchedResult}
          reset={reloadPage}
        />
      )}
    </>
  );
};

export default VideoFragment;
