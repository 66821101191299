import { useEffect, useRef, useState } from "react";
import { constraints } from "../../utils/constants";
import { drawROI, heicToURL } from "../../utils/functions";
import { useAppContext } from "../../utils/context/AppProvider";
import useRequest from "../../hooks/useRequest";

const useVideoFragment = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const ROIcanvasRef = useRef<HTMLCanvasElement>(null);
  const [currentCam, setCurrentCam] = useState("user");
  const [videoLoader, setVideoLoader] = useState(false);

  const {
    setRequestLoader,
    setMessage,
    setDocImageParams,
    setImageUploaded,
    imageUploaded,
  } = useAppContext();
  const { makeDetectRequest } = useRequest();

  const makeSnapshot = () => {
    const canvas = canvasRef.current!;
    const videoElem = videoRef.current!;
    canvas.width = videoElem.videoWidth;
    canvas.height = videoElem.videoHeight;
    const ctx = canvas.getContext("2d")!;
    const mirror = currentCam === "user" ? -1 : 1; // -1 -> mirror
    ctx.scale(mirror, 1);
    ctx.drawImage(videoElem, 0, 0, canvas.width * mirror, canvas.height);
    const imgUrl = canvas.toDataURL("image/jpeg");
    canvas.toBlob((blob) => {
      makeDetectRequest(
        blob!,
        imgUrl,
        videoElem.videoWidth,
        videoElem.videoHeight,
        videoElem
      );
    }, "image/jpeg");
  };

  const getUserStream = (videoElement: HTMLVideoElement, constraints: {}) => {
    setVideoLoader(true);
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        videoElement.srcObject = stream;
        videoElement.play();
        videoElement.addEventListener("loadeddata", () => {
          drawROI(
            ROIcanvasRef.current!,
            videoElement.videoWidth,
            videoElement.videoHeight
          );
        });
      })
      .catch((e: any) => {
        if (e.code === 0) {
          setMessage(
            "Доступ к камере был запрещен. Разрешите доступ к камере и перезагрузите страницу."
          );
        }
        console.log(e.code);
      })
      .finally(() => {
        setVideoLoader(false);
      });
  };

  const switchCam = () => {
    setCurrentCam((prev) => {
      const switchTo = prev === "user" ? "environment" : "user";
      return switchTo;
    });
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    let objectURL = URL.createObjectURL(file);
    if (file.name.includes(".HEIC")) {
      setRequestLoader(true);
      objectURL = await heicToURL(file);
    }
    const image = new Image();
    image.onload = () => {
      const { width, height } = image;
      setDocImageParams({ width, height });
      const canvas = canvasRef.current!;
      const ctx = canvas.getContext("2d")!;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(image, 0, 0, width, height);
      const imgUrl = canvas.toDataURL("image/jpeg");
      setImageUploaded(true);
      canvas.toBlob((blob) => {
        makeDetectRequest(blob!, imgUrl, width, height, videoRef.current!);
      }, "image/jpeg");
    };
    image.onerror = (e) => {
      console.log(e);
      setMessage("Не удалось загрузить данное изображение!");
    };
    image.src = objectURL;
  };

  const reloadPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    const { width, height } = constraints.video;
    getUserStream(videoRef.current!, {
      audio: false,
      video: {
        width,
        height,
        facingMode: currentCam,
      },
    });
  }, [currentCam]);

  return {
    videoLoader,
    currentCam,
    videoRef,
    canvasRef,
    ROIcanvasRef,
    makeSnapshot,
    switchCam,
    handleUpload,
    reloadPage,
    imageUploaded,
  };
};

export default useVideoFragment;
