import { Alert, Button } from "@mui/material";
import { useLayoutEffect, useRef } from "react";
import { similarity_threshold } from "../utils/constants";
import styles from "./results.module.scss";
import gsap from "gsap";
import ReplayIcon from "@mui/icons-material/Replay";

type Props = {
  image1Bbox: number[];
  image2Bbox: number[];
  matchedResult: number;
  image1URL: string;
  image2URL: string;
  image1Params: { width: number; height: number };
  image2Params: { width: number; height: number };
  reset: () => void;
};

const Results = ({
  image1Bbox,
  image2Bbox,
  image1URL,
  image2URL,
  image1Params,
  image2Params,
  matchedResult,
  reset,
}: Props) => {
  const root = useRef(null);
  useLayoutEffect(() => {
    gsap.context((self) => {
      gsap.fromTo(
        `.${styles["results__img"]}`,
        { opacity: 0 },
        { opacity: 1, duration: 1 }
      );
      gsap.fromTo(
        `.${styles["results__img"]}`,
        { y: 200 },
        { y: 0, duration: 1, stagger: 0.2 }
      );
    }, root);
  }, []);

  return (
    <div ref={root} className={styles["results__container"]}>
      <Alert
        severity={matchedResult > similarity_threshold ? "success" : "error"}
      >
        {`Схожесть: ${(matchedResult * 100).toFixed(1)}%. `}
        <strong>{`${
          matchedResult > similarity_threshold ? "OK" : "FAIL"
        }`}</strong>
      </Alert>
      <svg
        width="100%"
        viewBox={`0 0 ${image1Params.width} ${image1Params.height}`}
        className={`${styles["results__img"]}`}
      >
        <image href={image1URL!} />
        <rect
          x={image1Bbox[0]}
          y={image1Bbox[1]}
          width={image1Bbox[2]}
          height={image1Bbox[3]}
          stroke="blue"
          strokeWidth="6"
          fillOpacity="0"
        />
      </svg>
      <svg
        width="100%"
        viewBox={`0 0 ${image2Params.width} ${image2Params.height}`}
        className={`${styles["results__img"]}`}
      >
        <image href={image2URL!} />
        <rect
          x={image2Bbox[0]}
          y={image2Bbox[1]}
          width={image2Bbox[2]}
          height={image2Bbox[3]}
          stroke="blue"
          strokeWidth={image2Params.width / 300}
          fillOpacity="0"
        />
      </svg>
      <Button
        onClick={reset}
        endIcon={<ReplayIcon />}
        variant="contained"
        sx={{ alignSelf: "center" }}
      >
        Повторить
      </Button>
    </div>
  );
};

export default Results;
