import VideoFragment from "./components/VideoFragment";
import styles from "./app.module.scss";
import { AppProvider } from "./utils/context/AppProvider";

declare global {
  interface Window {
    FACE_FD_ROI_RATIO: number;
    FACE_FD_ROI_PADDING: number;
    FACE_LIVENESS_THRESHOLD: number;
    FACE_FD_MIN_HEIGHT_ROI_FRAC: number;
    DOCUMENT_FD_MIN_HEIGHT_FRAC: number;
    SIMILARITY_THRESHOLD: number;
    FACE_FD_ROI_VISUALIZATION_SHRINK_FRAC: number;
    API_URL: string;
    API_TOKEN: string;
    SENTRY_DSN: string;
    SENTRY_TRACES_RATE: string;
    SENTRY_ENVIRONMENT: string;
  }
}

function App() {
  return (
    <AppProvider>
      <div className={styles["wrapper"]}>
        <VideoFragment />
      </div>
    </AppProvider>
  );
}

export default App;
