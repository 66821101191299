import React, { createContext, useState } from "react";

type AppContextType = {
  loadingMessage: string;
  setLoadingMessage: React.Dispatch<React.SetStateAction<string>>;
  matched: boolean;
  setMatched: React.Dispatch<React.SetStateAction<boolean>>;
  matchedResult: number;
  setMatchedResult: React.Dispatch<React.SetStateAction<number>>;
  requestLoader: boolean;
  setRequestLoader: React.Dispatch<React.SetStateAction<boolean>>;
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  personImage: Blob | null;
  setPersonImage: React.Dispatch<React.SetStateAction<Blob | null>>;
  personBbox: number[];
  setPersonBbox: React.Dispatch<React.SetStateAction<number[]>>;
  docBbox: number[];
  setDocBbox: React.Dispatch<React.SetStateAction<number[]>>;
  personImageURL: string | null;
  setPersonImageURL: React.Dispatch<React.SetStateAction<string | null>>;
  docImageURL: string | null;
  setDocImageURL: React.Dispatch<React.SetStateAction<string | null>>;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  docImageParams: {
    width: number;
    height: number;
  } | null;
  setDocImageParams: React.Dispatch<
    React.SetStateAction<{
      width: number;
      height: number;
    } | null>
  >;
  imageUploaded: boolean;
  setImageUploaded: React.Dispatch<React.SetStateAction<boolean>>;
};

type ProviderType = {
  children: React.ReactNode;
};

const AppContext = createContext({} as AppContextType);

const AppProvider = ({ children }: ProviderType) => {
  const [loadingMessage, setLoadingMessage] = useState("Подключаем камеру");
  const [matched, setMatched] = useState(false);
  const [matchedResult, setMatchedResult] = useState(0);
  const [requestLoader, setRequestLoader] = useState(false);
  const [message, setMessage] = useState(
    "Поместите лицо в рамку и сделайте фото"
  );
  const [personImage, setPersonImage] = useState<Blob | null>(null);
  const [personImageURL, setPersonImageURL] = useState<string | null>(null);
  const [docImageURL, setDocImageURL] = useState<string | null>(null);
  const [personBbox, setPersonBbox] = useState<number[]>([]);
  const [docBbox, setDocBbox] = useState<number[]>([]);
  const [step, setStep] = useState(0);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [docImageParams, setDocImageParams] = useState<{
    width: number;
    height: number;
  } | null>(null);
  return (
    <AppContext.Provider
      value={{
        loadingMessage,
        setLoadingMessage,
        matched,
        setMatched,
        matchedResult,
        setMatchedResult,
        requestLoader,
        setRequestLoader,
        message,
        setMessage,
        personImage,
        setPersonImage,
        personImageURL,
        setPersonImageURL,
        docImageURL,
        setDocImageURL,
        personBbox,
        setPersonBbox,
        docBbox,
        setDocBbox,
        step,
        setStep,
        docImageParams,
        setDocImageParams,
        imageUploaded,
        setImageUploaded,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error("App context is undefined");
  }
  return context;
};

export { AppProvider, useAppContext };
