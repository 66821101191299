import { Button } from "@mui/material";
import { checkMobile } from "../../utils/functions";
import styles from "./controlButtons.module.scss";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import CameraIcon from "@mui/icons-material/Camera";
import FileUploadIcon from "@mui/icons-material/FileUpload";

type Props = {
  handleUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  switchCam: () => void;
  makeSnapshot: () => void;
  showUploadButton: boolean;
  loader: boolean;
  show: boolean;
};

const ControlButtons = ({
  handleUpload,
  switchCam,
  makeSnapshot,
  loader,
  showUploadButton,
  show,
}: Props) => {
  if (show) {
    return (
      <div className={styles["container"]}>
        {checkMobile() && (
          <Button onClick={switchCam} variant="outlined">
            <FlipCameraIosIcon />
          </Button>
        )}
        <Button disabled={loader} onClick={makeSnapshot} variant="contained">
          <CameraIcon />
        </Button>
        {showUploadButton && (
          <div>
            <Button disabled={loader} endIcon={<FileUploadIcon />}>
              <label htmlFor="upload">Загрузить</label>
            </Button>
            <input
              type="file"
              id="upload"
              style={{ display: "none" }}
              onChange={handleUpload}
            />
          </div>
        )}
      </div>
    );
  }
  return null;
};

export default ControlButtons;
