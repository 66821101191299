import axios, { AxiosResponse } from "axios";
import {
  base_url,
  doc_fd_min_h_frac,
  face_fd_min_h_roi_frac,
  token,
} from "../utils/constants";
import { useAppContext } from "../utils/context/AppProvider";
import {
  bboxIsInsideRoi,
  calculateRoiPosition,
  isImageValid,
} from "../utils/functions";
import { DetectResponse, MatchResponse } from "../utils/types";

const useRequest = () => {
  const {
    setLoadingMessage,
    setMatchedResult,
    setMatched,
    setMessage,
    setRequestLoader,
    personImage,
    setPersonImage,
    personImageURL,
    personBbox,
    setPersonBbox,
    setStep,
    setDocBbox,
    setPersonImageURL,
    setDocImageURL,
    setDocImageParams,
  } = useAppContext();

  const comparePhotos = (
    image1: Blob,
    image2: Blob,
    bbox1: number[],
    bbox2: number[]
  ) => {
    setLoadingMessage("Сравниваем");
    const formData = new FormData();
    formData.append("image1", image1);
    formData.append("image2", image2);
    axios({
      url: `${base_url}/match?face1=${bbox1.join(",")}&face2=${bbox2.join(
        ","
      )}`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      data: formData,
    })
      .then((res: AxiosResponse<MatchResponse>) => {
        setMatchedResult(res.data.data.score);
        setMatched(true);
        setMessage("");
      })
      .catch((e) => {
        setMessage("Упс! Что-то пошло не так!");
      })
      .finally(() => {
        setRequestLoader(false);
      });
  };

  const makeDetectRequest = (
    blob: Blob,
    imgUrl: string,
    imageWidth: number,
    imageHeight: number,
    videoElement: HTMLVideoElement
  ) => {
    setRequestLoader(true);
    setLoadingMessage("Обрабатываем фото");
    setMessage("");
    const [x, y, w, h] = calculateRoiPosition(
      videoElement.videoWidth,
      videoElement.videoHeight
    );
    axios({
      url: `${base_url}/detect?fd_min_size=${
        personImageURL
          ? (doc_fd_min_h_frac * imageHeight).toFixed(0)
          : (face_fd_min_h_roi_frac * h).toFixed(0)
      }${personImageURL ? "" : "&liveness=true"}`,
      method: "POST",
      headers: {
        "Content-Type": "image/jpeg",
        "Content-Length": blob!.size.toString(),
        Authorization: `Bearer ${token}`,
      },
      data: blob,
    })
      .then((res: AxiosResponse<DetectResponse>) => {
        const facesDetected = res.data.data.length;
        if (!facesDetected) {
          setMessage("На фото не найдено лиц! Повторите попытку");
          setRequestLoader(false);
          return;
        }
        const { bbox, liveness } = res.data.data[0];
        const bboxInsideRoi = bboxIsInsideRoi(bbox, {
          x,
          y,
          width: w,
          height: h,
        });

        const errorMessage = isImageValid(
          facesDetected,
          bboxInsideRoi,
          !!personImage,
          liveness
        );
        if (errorMessage) {
          setMessage(errorMessage);
          setRequestLoader(false);
          return;
        }

        if (personImage === null) {
          setPersonImage(blob);
          setPersonBbox([bbox.x, bbox.y, bbox.width, bbox.height]);
          setPersonImageURL(imgUrl);
          setMessage("Сделайте фото документа или загрузите его фото");
          setStep((prev) => ++prev);
          setRequestLoader(false);
        } else {
          const bboxArray = [bbox.x, bbox.y, bbox.width, bbox.height];
          setDocBbox(bboxArray);
          setDocImageURL(imgUrl);
          setDocImageParams({
            width: imageWidth,
            height: imageHeight,
          });
          comparePhotos(personImage, blob!, personBbox, bboxArray);
          setStep((prev) => ++prev);
        }
      })
      .catch((e) => {
        console.log(e);
        setMessage("Фото не подошло, попробуйте еще раз");
        setRequestLoader(false);
      });
  };

  return { comparePhotos, makeDetectRequest };
};

export default useRequest;
