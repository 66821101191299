import { Alert, Typography } from "@mui/material";
import { steps } from "../../utils/constants";
import { useAppContext } from "../../utils/context/AppProvider";

const StepAndNotification = ({ show }: { show: boolean }) => {
  const { message, step } = useAppContext();
  if (show) {
    return (
      <>
        <Typography variant="h5">{`Шаг ${step + 1}: ${
          steps[step]
        }`}</Typography>
        <br />
        <Alert severity="info" sx={{ opacity: message ? 1 : 0, mb: 2 }}>
          {message}
        </Alert>
      </>
    );
  }
  return null;
};

export default StepAndNotification;
