import { CircularProgress, Typography } from "@mui/material";
import styles from "./requestLoader.module.scss";

type Props = {
  colorBlue: boolean;
  text: string;
  show: boolean;
};

const RequestLoader = ({ colorBlue, text, show }: Props) => {
  if (show) {
    return (
      <div
        className={`${styles["loader"]} ${
          colorBlue ? styles["loader__blue"] : ""
        }`}
      >
        <CircularProgress color="inherit" />
        <Typography>{text}</Typography>
      </div>
    );
  }
  return null;
};

export default RequestLoader;
